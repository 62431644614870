"use client"

import { useState, useEffect } from "react"
import { UserAuth } from "../../context/AuthContext"
import { FaCalendarDay, FaExclamationTriangle, FaExchangeAlt, FaFilePdf } from "react-icons/fa"
import { get, getDatabase, ref, update } from "firebase/database"
import ExerciseSwapModal from "./ExerciseSwapModal"
import jsPDF from "jspdf"

// Helper function to get proxy URL
const getProxyImageUrl = (originalUrl) => {
  if (!originalUrl) return "/placeholder.svg"
  // Use the full URL to the backend API
  return `${process.env.REACT_APP_API_URL}/proxy-image?url=${encodeURIComponent(originalUrl)}`
}

// Remove or comment out the unused findExerciseDetails function
// Helper function to find exercise details from the database
/* 
const findExerciseDetails = (exerciseName) => {
  // Search through all categories in both exercise databases
  const allCategories = [...Object.values(Exercicios), ...Object.values(ExerciciosEmCasa)]

  for (const category of allCategories) {
    const exercise = category.find((ex) => ex.exercicio === exerciseName)
    if (exercise) {
      return exercise
    }
  }

  return null
}
*/

// Helper function to load an image via the proxy with better error handling
const loadImageViaProxy = (imageUrl) => {
  return new Promise((resolve, reject) => {
    if (!imageUrl) {
      reject(new Error("No image URL provided"))
      return
    }

    const proxyUrl = getProxyImageUrl(imageUrl)
    const img = new Image()
    img.crossOrigin = "anonymous"

    img.onload = () => resolve(img)
    img.onerror = (e) => reject(new Error(`Failed to load image: ${e}`))

    img.src = proxyUrl

    // Set a timeout to prevent hanging
    setTimeout(() => {
      if (!img.complete) {
        reject(new Error("Image load timeout"))
      }
    }, 5000) // Reduced timeout to 5 seconds
  })
}

const DaySection = ({ title, content, isVisible, onToggle, onSwapExercise, remainingSwaps }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
    <div
      className="flex items-center justify-between p-4 cursor-pointer bg-[#8D24FF]/10 hover:bg-[#8D24FF]/20"
      onClick={onToggle}
    >
      <div className="flex items-center">
        <FaCalendarDay className="text-[#190076]/70 text-xl mr-2" />
        <h3 className="text-lg font-semibold text-[#190076]">{title}</h3>
      </div>
      <span className="text-[#190076]/70">{isVisible ? "▲" : "▼"}</span>
    </div>
    {isVisible && (
      <div className="p-4 bg-white">
        {content.map((exercise, index) => (
          <div key={`${exercise.exercise?.exercicio || index}-${index}`} className="mb-6 border-b pb-4">
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold text-lg text-[#190076]">
                {exercise.exercise?.exercicio || "Exercício não especificado"}
              </p>
              {remainingSwaps[title] > 0 && (
                <button
                  onClick={() => onSwapExercise(title, index)}
                  className="bg-[#8D24FF] text-white px-2 py-1 rounded hover:bg-[#7B1FE0] transition duration-300 flex items-center"
                >
                  <FaExchangeAlt className="mr-1" />
                  Trocar ({remainingSwaps[title]})
                </button>
              )}
            </div>
            {exercise.exercise?.gif && (
              <img
                src={getProxyImageUrl(exercise.exercise.gif) || "/placeholder.svg"}
                alt={exercise.exercise.exercicio || "Exercício"}
                className="w-full max-w-xs mx-auto mt-2 mb-2 rounded-lg shadow-md"
                id={`exercise-image-${title.toLowerCase()}-${index}`}
                data-exercise-name={exercise.exercise.exercicio}
              />
            )}
            {exercise.exercise?.description && (
              <p className="text-sm text-[#190076]/70 italic mb-2">{exercise.exercise.description}</p>
            )}
            {exercise.group === "Cardio" ? (
              <p className="text-sm text-[#190076]/80">{`Duração: ${exercise.duration || exercise.reps} `}</p>
            ) : (
              <p className="text-sm text-[#190076]/80">{`${exercise.sets || "-"} séries x ${exercise.reps || "-"} repetições`}</p>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
)

export default function LocalTreino() {
  const [currentWorkout, setCurrentWorkout] = useState(null)
  const [expandedDays, setExpandedDays] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [remainingSwaps, setRemainingSwaps] = useState({})
  const [showSwapModal, setShowSwapModal] = useState(false)
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [generatingPDF, setGeneratingPDF] = useState(false)
  const [pdfProgress, setPdfProgress] = useState(0)
  const { user } = UserAuth()

  useEffect(() => {
    const fetchWorkout = async () => {
      console.log("Fetching workout...")
      setError(null)
      try {
        if (user?.uid) {
          const db = getDatabase()
          const userRef = ref(db, `users/${user.uid}`)
          const snapshot = await get(userRef)

          if (snapshot.exists()) {
            const userData = snapshot.val()
            setCurrentWorkout(userData.treinosGerados || userData.currentWorkout)
            setRemainingSwaps(userData.trocasRestantes || {})
            setExpandedDays(
              Object.fromEntries(
                Object.keys(userData.treinosGerados || userData.currentWorkout).map((day) => [day, true]),
              ),
            )
          } else {
            console.log("No workout found")
          }
        }
      } catch (error) {
        console.error("Error fetching workout:", error)
        setError("Ocorreu um erro ao buscar seu treino. Por favor, tente novamente mais tarde.")
      } finally {
        setIsLoading(false)
      }
    }
    //
    fetchWorkout()
  }, [user])

  const toggleDayExpansion = (day) => {
    setExpandedDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }))
  }

  const sortDaysOfWeek = (workoutData) => {
    const order = ["segunda", "terça", "quarta", "quinta", "sexta", "sábado", "domingo"]

    const formattedWorkoutData = Object.entries(workoutData).map(([key, value]) => {
      let dayName = key.toLowerCase()
      if (dayName === "monday") dayName = "segunda"
      if (dayName === "tuesday") dayName = "terça"
      if (dayName === "wednesday") dayName = "quarta"
      if (dayName === "thursday") dayName = "quinta"
      if (dayName === "friday") dayName = "sexta"
      if (dayName === "saturday") dayName = "sábado"
      if (dayName === "sunday") dayName = "domingo"

      return [dayName, value]
    })

    return formattedWorkoutData.sort(([dayA], [dayB]) => order.indexOf(dayA) - order.indexOf(dayB))
  }

  const handleSwapExercise = (day, index) => {
    const exerciseData = currentWorkout[day][index]
    setSelectedExercise({
      day,
      index,
      exercise: exerciseData.exercise || {},
      group: exerciseData.group || "",
      isHomeWorkout: exerciseData.exercise?.isHomeWorkout || false,
    })
    setShowSwapModal(true)
  }

  const performSwap = async (newExercise) => {
    if (remainingSwaps[selectedExercise.day] > 0 && user?.uid) {
      const db = getDatabase()
      const userRef = ref(db, `users/${user.uid}`)

      try {
        const updatedWorkout = { ...currentWorkout }
        updatedWorkout[selectedExercise.day][selectedExercise.index] = {
          ...updatedWorkout[selectedExercise.day][selectedExercise.index],
          exercise: newExercise,
        }

        const newRemainingSwaps = { ...remainingSwaps }
        newRemainingSwaps[selectedExercise.day] -= 1

        await update(userRef, {
          treinosGerados: updatedWorkout,
          trocasRestantes: newRemainingSwaps,
        })

        setCurrentWorkout(updatedWorkout)
        setRemainingSwaps(newRemainingSwaps)
        setShowSwapModal(false)
      } catch (error) {
        console.error("Error updating workout:", error)
        setError("Ocorreu um erro ao trocar o exercício. Por favor, tente novamente.")
      }
    }
  }

  // Function to convert an image to a data URL for PDF
  const imageToDataUrl = (img) => {
    try {
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0)
      return canvas.toDataURL("image/jpeg", 0.75) // Use JPEG with 75% quality for smaller file size
    } catch (error) {
      console.error("Error converting image to data URL:", error)
      return null
    }
  }

  // Função para gerar um PDF sem imagens (fallback)
  const generatePDFWithoutImages = async () => {
    try {
      const pdf = new jsPDF("p", "mm", "a4")

      // Configurações de layout
      const margin = 15
      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      // Adiciona título na primeira página
      let yOffset = 15
      pdf.setFontSize(16)
      pdf.setTextColor(25, 0, 118) // Azul escuro
      pdf.text("Seu Treino Personalizado", pageWidth / 2, yOffset, { align: "center" })
      yOffset += 15

      // Processa cada dia do treino
      const sortedDays = sortDaysOfWeek(currentWorkout)
      for (let dayIndex = 0; dayIndex < sortedDays.length; dayIndex++) {
        const [day, exercises] = sortedDays[dayIndex]

        // Sempre inicia um novo dia em uma nova página (exceto o primeiro dia)
        if (dayIndex > 0) {
          pdf.addPage()
          yOffset = 15
        }

        const dayTitle = day.charAt(0).toUpperCase() + day.slice(1)

        // Adiciona título do dia com fundo roxo claro
        pdf.setFillColor(210, 180, 255) // Roxo claro (R:210, G:180, B:255)
        pdf.rect(margin - 5, yOffset - 8, pageWidth - margin * 2 + 10, 12, "F")

        pdf.setFontSize(14)
        pdf.setTextColor(25, 0, 118) // Texto em azul escuro para contrastar com o fundo roxo claro
        pdf.text(dayTitle, margin, yOffset)

        yOffset += 10

        // Processa cada exercício do dia
        for (let i = 0; i < exercises.length; i++) {
          const exercise = exercises[i]
          const exerciseName = exercise.exercise?.exercicio || "Exercício não especificado"

          // Verifica se o exercício cabe na página atual, senão cria uma nova página
          if (yOffset > pageHeight - 40) {
            pdf.addPage()
            yOffset = 15
          }

          // Adiciona nome do exercício
          pdf.setFontSize(12)
          pdf.setTextColor(25, 0, 118)
          pdf.text(exerciseName, margin, yOffset)
          yOffset += 6

          // Adiciona descrição do exercício (se disponível)
          if (exercise.exercise?.description) {
            pdf.setFontSize(9)
            pdf.setTextColor(100, 100, 100)

            const description = exercise.exercise.description
            const splitDescription = pdf.splitTextToSize(description, pageWidth - margin * 2)

            pdf.text(splitDescription, margin, yOffset)
            yOffset += splitDescription.length * 4 + 2
          }

          // Adiciona detalhes do exercício
          pdf.setFontSize(10)
          pdf.setTextColor(50, 50, 50)

          if (exercise.group === "Cardio") {
            pdf.text(`Duração: ${exercise.duration || exercise.reps}`, margin, yOffset)
          } else {
            pdf.text(`${exercise.sets || "-"} séries x ${exercise.reps || "-"} repetições`, margin, yOffset)
          }

          yOffset += 8

          // Adiciona linha separadora entre exercícios (exceto após o último)
          if (i < exercises.length - 1) {
            pdf.setDrawColor(220, 220, 220)
            pdf.setLineWidth(0.1)
            pdf.line(margin + 5, yOffset - 4, pageWidth - margin - 5, yOffset - 4)
            yOffset += 2
          }
        }

        // Adiciona espaço entre os dias
        if (dayIndex < sortedDays.length - 1) {
          yOffset += 15

          // Adiciona uma linha separadora entre os dias
          pdf.setDrawColor(141, 36, 255, 0.5)
          pdf.setLineWidth(0.5)
          pdf.line(margin, yOffset - 8, pageWidth - margin, yOffset - 8)
        }
      }

      // Salva o PDF
      pdf.save("Treino Personal Intelingente.pdf")
      return true
    } catch (error) {
      console.error("Erro ao gerar PDF sem imagens:", error)
      return false
    }
  }

  const generatePDF = async () => {
    if (generatingPDF) return

    try {
      setGeneratingPDF(true)
      setPdfProgress(0)

      const pdf = new jsPDF("p", "mm", "a4")

      // Armazena o estado original dos dias expandidos
      const originalExpandedState = { ...expandedDays }

      // Expande todas as seções para o PDF
      const allDaysExpanded = Object.keys(currentWorkout).reduce((acc, day) => {
        acc[day] = true
        return acc
      }, {})

      setExpandedDays(allDaysExpanded)

      // Configurações de layout
      const margin = 15
      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      // Adiciona título na primeira página
      let yOffset = 15
      pdf.setFontSize(16)
      pdf.setTextColor(25, 0, 118) // Azul escuro
      pdf.text("Seu Treino Personalizado", pageWidth / 2, yOffset, { align: "center" })
      yOffset += 15

      // Contagem total de exercícios para barra de progresso
      let totalExercises = 0
      let processedExercises = 0
      let imageLoadErrors = 0

      for (const [, exercises] of sortDaysOfWeek(currentWorkout)) {
        totalExercises += exercises.length
      }

      // Processa cada dia do treino
      const sortedDays = sortDaysOfWeek(currentWorkout)
      for (let dayIndex = 0; dayIndex < sortedDays.length; dayIndex++) {
        const [day, exercises] = sortedDays[dayIndex]

        // Sempre inicia um novo dia em uma nova página (exceto o primeiro dia)
        if (dayIndex > 0) {
          pdf.addPage()
          yOffset = 15
        }

        const dayTitle = day.charAt(0).toUpperCase() + day.slice(1)

        // Adiciona título do dia com fundo roxo claro
        pdf.setFillColor(210, 180, 255) // Roxo claro (R:210, G:180, B:255)
        pdf.rect(margin - 5, yOffset - 8, pageWidth - margin * 2 + 10, 12, "F")

        pdf.setFontSize(14)
        pdf.setTextColor(25, 0, 118) // Texto em azul escuro para contrastar com o fundo roxo claro
        pdf.text(dayTitle, margin, yOffset)

        // Não adiciona linha separadora após o título, pois já temos o fundo colorido
        yOffset += 10

        // Processa cada exercício do dia
        for (let i = 0; i < exercises.length; i++) {
          const exercise = exercises[i]
          const exerciseName = exercise.exercise?.exercicio || "Exercício não especificado"

          // Calcula altura aproximada que o exercício ocupará
          let estimatedHeight = 20 // Altura base reduzida

          if (exercise.exercise?.description) {
            const description = exercise.exercise.description
            const splitDescription = pdf.splitTextToSize(description, pageWidth - margin * 2)
            estimatedHeight += splitDescription.length * 4
          }

          if (exercise.exercise?.gif) {
            estimatedHeight += 60 // Altura estimada reduzida para imagem
          }

          // Verifica se o exercício cabe na página atual, senão cria uma nova página
          if (yOffset + estimatedHeight > pageHeight - 15) {
            pdf.addPage()
            yOffset = 15
          }

          // Adiciona nome do exercício
          pdf.setFontSize(12)
          pdf.setTextColor(25, 0, 118)
          pdf.text(exerciseName, margin, yOffset)
          yOffset += 6

          // Busca detalhes do exercício e adiciona a imagem
          if (exerciseName !== "Exercício não especificado" && exercise.exercise?.gif) {
            try {
              const img = await loadImageViaProxy(exercise.exercise.gif)

              // Define dimensões da imagem proporcionalmente
              const imgWidth = 60 // Reduzido
              const imgHeight = (img.height * imgWidth) / img.width

              // Converte a imagem para Data URL
              const imgData = imageToDataUrl(img)

              if (imgData) {
                // Adiciona a imagem ao PDF
                pdf.addImage(imgData, "JPEG", (pageWidth - imgWidth) / 2, yOffset, imgWidth, imgHeight)
                yOffset += imgHeight + 3
              } else {
                throw new Error("Failed to convert image to data URL")
              }
            } catch (imgError) {
              imageLoadErrors++
              pdf.setFontSize(9)
              pdf.setTextColor(100, 100, 100)
              pdf.text("(Imagem disponível no aplicativo)", margin, yOffset)
              yOffset += 5

              // Se tivermos muitos erros de carregamento de imagem, podemos tentar gerar um PDF sem imagens
              if (imageLoadErrors > 3 && imageLoadErrors === processedExercises) {
                console.warn("Muitos erros de carregamento de imagem, tentando gerar PDF sem imagens")
                setExpandedDays(originalExpandedState)

                // Tenta gerar um PDF sem imagens como fallback
                const success = await generatePDFWithoutImages()
                if (success) {
                  setError("Não foi possível carregar as imagens. Um PDF sem imagens foi gerado.")
                  setGeneratingPDF(false)
                  setPdfProgress(0)
                  return
                }
              }
            }
          } else if (exerciseName !== "Exercício não especificado") {
            pdf.setFontSize(9)
            pdf.setTextColor(100, 100, 100)
            pdf.text("(Imagem indisponível)", margin, yOffset)
            yOffset += 5
          }

          // Adiciona descrição do exercício (se disponível)
          if (exercise.exercise?.description) {
            pdf.setFontSize(9)
            pdf.setTextColor(100, 100, 100)

            const description = exercise.exercise.description
            const splitDescription = pdf.splitTextToSize(description, pageWidth - margin * 2)

            pdf.text(splitDescription, margin, yOffset)
            yOffset += splitDescription.length * 4 + 2
          }

          // Adiciona detalhes do exercício
          pdf.setFontSize(10)
          pdf.setTextColor(50, 50, 50)

          if (exercise.group === "Cardio") {
            pdf.text(`Duração: ${exercise.duration || exercise.reps}`, margin, yOffset)
          } else {
            pdf.text(`${exercise.sets || "-"} séries x ${exercise.reps || "-"} repetições`, margin, yOffset)
          }

          yOffset += 8

          // Adiciona linha separadora entre exercícios (exceto após o último)
          if (i < exercises.length - 1) {
            pdf.setDrawColor(220, 220, 220)
            pdf.setLineWidth(0.1)
            pdf.line(margin + 5, yOffset - 4, pageWidth - margin - 5, yOffset - 4)
            yOffset += 2
          }

          // Atualiza progresso
          processedExercises++
          setPdfProgress(Math.round((processedExercises / totalExercises) * 100))
        }

        // Adiciona espaço entre os dias (exceto após o último)
        if (dayIndex < sortedDays.length - 1) {
          yOffset += 15

          // Adiciona uma linha separadora entre os dias
          pdf.setDrawColor(141, 36, 255, 0.5)
          pdf.setLineWidth(0.5)
          pdf.line(margin, yOffset - 8, pageWidth - margin, yOffset - 8)
        }
      }

      // Restaura o estado original dos dias expandidos
      setExpandedDays(originalExpandedState)

      // Salva o PDF
      pdf.save("Treino Personal Inteligente.pdf")
    } catch (error) {
      console.error("Erro ao gerar PDF:", error)
      setError("Ocorreu um erro ao gerar o PDF. Tentando gerar sem imagens...")

      // Tenta gerar um PDF sem imagens como fallback
      try {
        const success = await generatePDFWithoutImages()
        if (success) {
          setError("Não foi possível carregar as imagens. Um PDF sem imagens foi gerado.")
        } else {
          setError("Ocorreu um erro ao gerar o PDF. Por favor, tente novamente mais tarde.")
        }
      } catch (fallbackError) {
        console.error("Erro ao gerar PDF sem imagens:", fallbackError)
        setError("Ocorreu um erro ao gerar o PDF. Por favor, tente novamente mais tarde.")
      }
    } finally {
      setGeneratingPDF(false)
      setPdfProgress(0)
    }
  }

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-center text-[#190076]">Seu Treino Personalizado</h1>
        {currentWorkout && (
          <button
            onClick={generatePDF}
            disabled={generatingPDF}
            className={`bg-[#8D24FF] text-white px-4 py-2 rounded-lg hover:bg-[#7B1FE0] transition duration-300 flex items-center ${generatingPDF ? "opacity-70 cursor-not-allowed" : ""}`}
          >
            <FaFilePdf className="mr-2" />
            {generatingPDF ? `Gerando... ${pdfProgress}%` : "Baixar PDF"}
          </button>
        )}
      </div>
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div className="flex items-center">
            <FaExclamationTriangle className="mr-2" />
            <p>{error}</p>
          </div>
        </div>
      )}
      {isLoading ? (
        <p className="text-center text-[#190076]/70">Carregando seu treino...</p>
      ) : currentWorkout ? (
        <div id="workout-container" className="space-y-4">
          {sortDaysOfWeek(currentWorkout).map(([day, exercises]) => (
            <DaySection
              key={day}
              title={day.charAt(0).toUpperCase() + day.slice(1)}
              content={exercises}
              isVisible={expandedDays[day]}
              onToggle={() => toggleDayExpansion(day)}
              onSwapExercise={handleSwapExercise}
              remainingSwaps={remainingSwaps}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-[#190076]/70">Nenhum treino encontrado. Gere um novo treino para começar!</p>
      )}
      {showSwapModal && selectedExercise && (
        <ExerciseSwapModal
          onClose={() => setShowSwapModal(false)}
          onSwap={performSwap}
          currentExercise={selectedExercise}
          exerciseGroup={selectedExercise.group}
          isHomeWorkout={selectedExercise.isHomeWorkout}
          currentWorkout={currentWorkout}
          currentDay={selectedExercise.day}
          remainingSwaps={remainingSwaps}
        />
      )}
    </div>
  )
}

