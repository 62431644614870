"use client"
import pessoa1 from "../../img/Pessoa1.png"
import pessoa2 from "../../img/Pessoa2.png"
import pessoa3 from "../../img/Pessoa3.png"
import Mainloading from "../MainLoading/loading"

const openWhatsApp = () => {
  const numeros = ["24998824667"]
  const numeroAleatorio = numeros[Math.floor(Math.random() * numeros.length)]
  const whatsappURL = `https://api.whatsapp.com/send?phone=${numeroAleatorio}`
  window.open(whatsappURL, "_blank")
}

const Suporte = () => {
  return (
    <div className="text-center p-6 text-[#190076]">
      <Mainloading />
      <div className="flex justify-center mb-4">
        <div className="w-12 h-12 rounded-full border-2 border-[#8D24FF] overflow-hidden">
          <img src={pessoa1 || "/placeholder.svg"} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-[#8D24FF] overflow-hidden">
          <img src={pessoa2 || "/placeholder.svg"} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-[#8D24FF] overflow-hidden">
          <img src={pessoa3 || "/placeholder.svg"} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <h2 className="text-xl font-bold text-[#190076]">Precisando de Ajuda?</h2>
      <p className="text-[#190076]/80 mt-2">Fale conosco com um dos atendimentos abaixo</p>

      <button
        onClick={openWhatsApp}
        className="bg-[#8D24FF] text-white hover:bg-[#7315e6] mt-4 px-4 py-2 rounded-full inline-block text-center no-underline"
      >
        Falar no Whatsapp
      </button>
    </div>
  )
}

export default Suporte

