export default function Termos() {
    return (
      <div className="text-[#190076]">
        <header className="pt-3 text-center">
          <h1 className="font-bold text-[#190076]">Termos e Condições de Uso</h1>
        </header>
        <main>
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Natureza da Plataforma</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              A Personal Inteligente utiliza algoritmos avançados para processar as informações fornecidas por você, como
              preferências de treino para oferecer recomendações personalizadas adequadas ao seu perfil. Lembramos que as
              orientações fornecidas pela plataforma são de natureza geral e informativa, e não substituem a importância
              de um acompanhamento individualizado por um profissional de educação física qualificado. Ao utilizar a
              Personal Inteligente, você terá acesso a recomendações que podem ajudar na promoção de uma rotina de
              exercícios mais equilibrada e saudável, conforme as informações fornecidas. No entanto, é essencial entender
              que a plataforma não consegue levar em conta todas as particularidades específicas de cada caso, como
              condições físicas preexistentes, limitações médicas ou objetivos específicos de treinamento. Recomendamos
              que você compartilhe as orientações da Personal Inteligente com um profissional da área antes de realizar
              qualquer mudança significativa em sua rotina de treinos. Profissionais qualificados podem oferecer
              avaliações detalhadas e personalizadas, levando em consideração fatores que não podem ser adequadamente
              abordados apenas por meio de algoritmos. Ressaltamos ainda que as informações fornecidas à Personal
              Inteligente devem ser precisas e atualizadas para garantir que as recomendações sejam as mais adequadas
              possíveis. A plataforma é uma ferramenta de apoio e conscientização, mas a responsabilidade final por suas
              escolhas de treino e saúde permanece com você e seu profissional de confiança.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Orientação Profissional</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Recomendamos sempre que você consulte um profissional de educação física ou outro especialista antes de
              iniciar qualquer plano de treino. A Personal Inteligente não consegue levar em consideração todas as nuances
              individuais de saúde, limitações físicas, condições médicas preexistentes ou outros fatores que um
              profissional qualificado pode avaliar. Sua segurança é uma prioridade, e somente um profissional devidamente
              licenciado e com formação adequada pode fornecer orientações personalizadas, embasadas em conhecimento
              científico, considerando seu histórico e objetivos de treinamento. É importante entender que a Personal
              Inteligente é uma ferramenta que opera com base em algoritmos e informações fornecidas por você, mas não
              pode substituir o discernimento clínico e a expertise de um profissional humano. A interação direta com um
              profissional permite uma análise completa e abrangente, considerando todos os aspectos de sua saúde e
              bem-estar físico. Nunca hesite em buscar a opinião de um especialista antes de tomar decisões importantes
              relacionadas à sua saúde e rotina de treinos. Isso garantirá que você esteja tomando as medidas mais
              adequadas e seguras para alcançar suas metas de maneira sustentável e informada.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Limitações da Inteligência Artificial</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Entenda que a Personal Inteligente opera com base em algoritmos e dados disponíveis até o momento de sua
              última atualização. Embora envidemos esforços contínuos para fornecer orientações precisas e relevantes, a
              plataforma pode conter erros ou não estar atualizada com as informações mais recentes relacionadas ao
              treinamento físico. A ciência do exercício está em constante evolução, e novas descobertas podem influenciar
              as abordagens recomendadas. Portanto, é essencial que você use o bom senso ao interpretar as recomendações
              geradas pela plataforma. Considere-as como um ponto de partida para sua jornada de treinos, mas esteja
              ciente de que a consulta regular a profissionais confiáveis e atualizados é fundamental para tomar decisões
              informadas. Ao utilizar a Personal Inteligente, equilibre suas decisões com informações obtidas de fontes
              confiáveis e atualizadas, e não hesite em buscar o aconselhamento de um profissional de educação física
              sempre que necessário. A colaboração entre a tecnologia e a expertise humana é fundamental para garantir que
              você esteja fazendo escolhas seguras e bem fundamentadas para o seu bem-estar.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Responsabilidade e Riscos</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Ao usar a Personal Inteligente, você concorda em assumir total responsabilidade por suas decisões em relação
              à sua saúde e treinos. A utilização da plataforma é por sua conta e risco, e não nos responsabilizamos por
              qualquer consequência adversa resultante do uso ou má interpretação das recomendações da Personal
              Inteligente. É fundamental compreender que as recomendações geradas pela plataforma são baseadas em
              informações fornecidas por você e processadas por algoritmos, mas não substituem a orientação de um
              profissional qualificado. Ao optar por utilizar a Personal Inteligente, você deve estar ciente dos limites
              inerentes à tecnologia e do fato de que decisões relacionadas a treinos devem ser tomadas com base em uma
              variedade de fatores, incluindo informações atualizadas e aconselhamento especializado. Recomendamos
              fortemente que você busque a orientação de um profissional antes de implementar qualquer mudança
              significativa em sua rotina de treinos ou estilo de vida.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Modificações nos Termos</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Reservamo-nos o direito de atualizar ou modificar estes termos a qualquer momento. Recomendamos que você
              revise periodicamente esta página para se manter informado sobre quaisquer alterações. O uso contínuo da
              Personal Inteligente após quaisquer modificações nos termos indica sua aceitação dessas mudanças. É
              importante estar ciente dos termos mais recentes ao utilizar a plataforma.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Concordância com os Termos</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Ao continuar a usar a Personal Inteligente, você indica que leu, compreendeu e concordou com estes termos e
              condições de uso. Caso você tenha alguma dúvida ou preocupação, encorajamos você a entrar em contato conosco
              através dos canais de suporte indicados em nosso site.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Exclusão de Garantias</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              A Personal Inteligente é fornecida "como está", sem qualquer garantia expressa ou implícita. Não garantimos
              que a plataforma estará livre de erros ou que atenderá às suas expectativas. O uso da Personal Inteligente é
              por sua conta e risco. Recomendamos que você utilize a plataforma com discernimento e busque a orientação de
              profissionais para decisões específicas e críticas.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Resolução de Conflitos</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Qualquer disputa ou conflito relacionado ao uso da plataforma será regido pelas leis do BRASIL, e ambas as
              partes concordam em submeter-se à jurisdição exclusiva dos tribunais competentes da jurisdição da comarca de
              Volta Redonda, estado do Rio de Janeiro.
            </p>
          </section>
  
          <section className="text-justify">
            <h2 className="text-center mt-5 text-[#8D24FF] font-semibold">Política de Reembolso</h2>
            <p className="w-[90%] md:w-[65%] mx-auto">
              Política de Reembolso de 7 Dias - CDC (Código de Defesa do Consumidor) Nosso compromisso é oferecer a melhor
              experiência possível aos nossos usuários. Em consonância com o Código de Defesa do Consumidor (Lei nº
              8.078/1990), estabelecemos uma Política de Reembolso de 7 Dias, que permite aos nossos clientes exercerem o
              direito de arrependimento em suas compras online. O consumidor tem o direito de solicitar o reembolso de um
              produto ou serviço adquirido em nosso site no prazo de 7 (sete) dias corridos a partir da data de
              recebimento do produto ou da contratação do serviço, sem a necessidade de apresentar qualquer justificativa.
              O reembolso será processado de acordo com a forma de pagamento originalmente utilizada pelo consumidor,
              dentro de um prazo máximo de 7 (sete) dias úteis a partir da data de recebimento da solicitação de
              reembolso. O produto ou serviço a ser reembolsado deve ser devolvido em sua embalagem original, sem indícios
              de uso ou danos, acompanhado de todos os acessórios e manuais. Caso o produto não esteja em conformidade com
              essas condições, reservamo-nos o direito de rejeitar o reembolso ou aplicar uma taxa de depreciação
              proporcional. A política de reembolso de 7 dias não se aplica a produtos ou serviços que, por sua natureza,
              não possam ser devolvidos ou reembolsados, conforme previsto no Código de Defesa do Consumidor. Entendemos a
              importância de fornecer informações claras e detalhadas sobre nossa política de reembolso. Portanto,
              recomendamos que avalie cuidadosamente a decisão de adquirir acesso à Personal Inteligente antes de
              prosseguir com a compra. Em caso de dúvidas ou necessidade de esclarecimentos adicionais, sinta-se à vontade
              para entrar em contato conosco por meio dos canais de suporte fornecidos em nosso site. Agradecemos sua
              compreensão e sua escolha de utilizar a Personal Inteligente como parte de sua jornada de treinos e
              bem-estar. Estamos aqui para ajudá-lo a tomar decisões informadas e promover seu progresso com segurança.
            </p>
          </section>
        </main>
      </div>
    )
  }
  
  