export const Exercicios = {
    Biceps: [
      { id: 1, exercicio: "Barra Fixa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FBarra%20Fixa.gif?alt=media&token=e1436378-2a57-4a35-b3ff-0abae4e5b42c" , series: "", repetições: ""},
      { id: 2, exercicio: "Biceps Halter Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FBiceps%20Unilateral.gif?alt=media&token=e27d60ac-9278-4373-80db-8d726cd6a6f9", series: "", repetições: ""},
      { id: 3, exercicio: "Rosca Concentrada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2Frosca-concentrada-unilateral-com-halter.gif?alt=media&token=2dae7ca5-ccd7-44d3-80c9-89ec93b830f6", series: "", repetições: ""},
      { id: 5, exercicio: "Rosca Inclinada com Halter", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20Inclinada%20com%20Halter.gif?alt=media&token=f9ccdcea-2cca-4037-8b0c-3dc2e47c3f1e", series: "", repetições: ""},
      { id: 6, exercicio: "Rosca Inversa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20Inversa%20Barra.gif?alt=media&token=4e29cebe-d47d-4f58-8cd6-af00b30513d1", series: "", repetições: ""},
      { id: 7, exercicio: "Rosca Martelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca-Martelo.gif?alt=media&token=1851a43d-4337-4326-a589-ea5c76a762c5" , series: "", repetições: ""},
      { id: 8, exercicio: "Rosca Martelo na Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20Martelo%20na%20Corda.gif?alt=media&token=2c995df1-ba8a-46a7-ad2f-98726da55341", series:"", repetições: ""},
      { id: 9, exercicio: "Rosca Simultânea", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20Simultanea.gif?alt=media&token=444cef5b-1fcb-48b4-9ee7-a625ca85b398", series: "", repetições: ""},
      { id: 10, exercicio: "Rosca no Banco Scott", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20no%20Banco%20Scott.gif?alt=media&token=337319c2-a0ae-4206-9851-54f895dc953e" , series: "", repetições: ""},
      { id: 11, exercicio: "Rosca Direta com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca%20Direta%20com%20Barra.gif?alt=media&token=3f89d8c7-b664-48e6-ad27-232a3ca7f4ca" , series: "", repetições: ""},
      { id: 12, exercicio: "Rosca Alternada com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/biceps%2FRosca-Alternada-com-Halteres.gif?alt=media&token=bc9939ea-ac05-485e-9936-8fbc7bfa4ed9" , series: "", repetições: ""}
    ],
    Triceps: [
      // { id: 14, exercicio: "Extensão de Tríceps Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FExtens%C3%A3o%20de%20Triceps%20M%C3%A1quina.gif?alt=media&token=8b47c3de-53bb-4d5c-bad2-3d634bb60af5", series: "", repetições: ""},
      { id: 15, exercicio: "Extensão de Tríceps com Cabos Cruzados", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FExtens%C3%A3o%20de%20Triceps%20com%20Cabos%20Cruzados.gif?alt=media&token=eeed28b8-321f-495d-a907-54d1f8cf3618", series: "", repetições: ""},
      { id: 16, exercicio: "Extensão de Tríceps no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FExtens%C3%A3o%20de%20Tr%C3%ADceps%20no%20Banco.gif?alt=media&token=629c4f75-4648-498a-b7dd-46c61baedfab" , series: "", repetições: ""},
      { id: 17, exercicio: "Mergulho Tríceps", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FMergulho%20Triceps.gif?alt=media&token=1b19ef1a-c9e3-4a93-9368-c43eb7d0da7f", series: "", repetições: ""},
      { id: 18, exercicio: "Remada Curvada Fechada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FRemada%20Curvada%20Fechada.gif?alt=media&token=4749498d-7911-4a5d-be29-8e4ea508193e", series: "", repetições: ""},
      { id: 19, exercicio: "Tríceps Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FTriceps%20Corda.gif?alt=media&token=962f00b3-0088-48b8-a472-f2af01b643b3" , series: "", repetições: ""},
      { id: 20, exercicio: "Tríceps Francês", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FTriceps%20Frances.gif?alt=media&token=675c8f75-81f1-4b46-8bb5-644f8f94dae1", series: "", repetições: ""},
      { id: 21, exercicio: "Tríceps Testa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FTriceps%20Testa.gif?alt=media&token=aa329d05-47cf-4307-a753-54d8426281fa", series: "", repetições: ""},
      { id: 22, exercicio: "Tríceps na Polia com Barra V", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FTriceps%20na%20Polia%20Barra%20V.gif?alt=media&token=1e3fb742-ce23-46ca-b256-c01b6633460f" , series: "", repetições: ""}
    ],
    Costas: [
      // { id: 23, exercicio: "Barra Fixa Graviton", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FBarra%20Fixa%20Graviton.gif?alt=media&token=efa828e4-2de5-48bd-bc56-fa05472f2151" , series: "", repetições: ""},
      { id: 24, exercicio: "Crucifixo Invertido", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FCrucifixo%20Invertido.gif?alt=media&token=64dd7771-ed4d-41f4-a1a7-ed7c57074655", series: "", repetições: ""},
      { id: 25, exercicio: "Pulldown", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FPulldown.gif?alt=media&token=354d8f8c-1dd1-4648-9751-096c2f074e93", series: "", repetições: ""},
      { id: 26, exercicio: "Puxada Unilateral Crossover", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FPuxada%20Unilateral%20Crossover.gif?alt=media&token=98babb8f-d661-46d1-9860-358dea778bf1" , series: "", repetições: ""},
      { id: 27, exercicio: "Remada Curvada com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Curvada%20com%20Barra.gif?alt=media&token=131ad4d9-3eda-4e8e-a060-8c45a1fd0519", series: "", repetições: ""},
      { id: 28, exercicio: "Remada Alta com Halter", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FRemada%20Alta%20com%20Halter.gif?alt=media&token=579a9643-4442-4381-b983-13b0f2c788b5", series: "", repetições: ""},
      { id: 29, exercicio: "Remada Baixa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Baixa.gif?alt=media&token=46b3ec65-afc4-4f25-afa2-85f83b05a2f1" , series: "", repetições: ""},
      { id: 30, exercicio: "Remada Cavalinho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Cavalinho.gif?alt=media&token=762a9c85-210c-4a1c-8f40-0c8fe1b3ba7b", series: "", repetições: ""},
      { id: 31, exercicio: "Remada Inclinada Reversa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Inclinada%20Reversa.gif?alt=media&token=f2652307-d86f-4013-b018-a114c4693c36", series: "", repetições: ""},
      { id: 32, exercicio: "Remada Sentada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Sentada.gif?alt=media&token=ce6a7dad-cb12-4197-94b1-2acc5fc723fa" , series: "", repetições: ""},
      { id: 33, exercicio: "Remada Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FRemada%20Unilateral.gif?alt=media&token=8519222a-4b1c-4093-9e60-ea09f53eeb8c", series: "", repetições: ""},
      { id: 34, exercicio: "Remada no Cabo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FRemada%20no%20Cabo.gif?alt=media&token=8abf53a6-69d0-42ef-8a4d-b363108578bc", series: "", repetições: ""},
      { id: 35, exercicio: "Pulldown na Polia Alta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/costas%2FPulldown%20na%20Polia%20Alta.gif?alt=media&token=d293da7f-8fea-4d9b-897a-1eb0107c9431", series: "", repetições: ""}
    ],
    Ombro: [
      { id: 36, exercicio: "Desenvolvimento Arnold", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FDesenvolvimento%20Arnold.gif?alt=media&token=912638aa-1e6d-40fe-9c6e-8c634150555f" , series: "", repetições: ""},
      { id: 37, exercicio: "Desenvolvimento Arnold Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FDesenvolvimento%20Arnold%20Unilateral.gif?alt=media&token=70b196a7-a687-4da8-bff1-2744d87fe803", series: "", repetições: ""},
      { id: 38, exercicio: "Desenvolvimento Maquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FDesenvolvimento%20Maquina.gif?alt=media&token=2c19370d-6f87-429f-842f-3c322171d297", series: "", repetições: ""},
      { id: 39, exercicio: "Desenvolvimento com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FDesenvolvimento%20com%20Barra.gif?alt=media&token=186d4251-8ca4-41e7-89cb-485674fd26ef" , series: "", repetições: ""},
      { id: 40, exercicio: "Elevação Frontal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FEleva%C3%A7%C3%A3o%20Frontal.gif?alt=media&token=359f12d3-bbfe-4840-a40c-f3fe3b53ba95", series: "", repetições: ""},
      { id: 41, exercicio: "Elevação Lateral com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FEleva%C3%A7%C3%A3o%20Lateral.gif?alt=media&token=26570844-42e3-4829-8a19-fe97d4c40000", series: "4", repetições: "20"},
      { id: 42, exercicio: "Elevação Lateral Inclinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FEleva%C3%A7%C3%A3o%20Lateral%20Inclinada.gif?alt=media&token=aa5c19b3-de8e-40cf-9af9-0b22c633b655" , series: "", repetições: ""},
      { id: 43, exercicio: "Elevação Lateral na Polia Baixa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FEleva%C3%A7%C3%A3o%20Lateral%20na%20Polia%20Baixa.gif?alt=media&token=87ecb150-89e0-4450-9941-21c2cf9a8cef", series: "", repetições: ""},
      { id: 44, exercicio: "Encolhimento de Ombros com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/ombro%2FEncolhimento%20de%20Ombros%20com%20Halter.gif?alt=media&token=940e2d85-0f88-41d1-b29a-cbb6eb9a3ac4", series: "", repetições: ""},
      { id: 45, exercicio: "Mergulho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/triceps%2FMergulho%20Triceps.gif?alt=media&token=1b19ef1a-c9e3-4a93-9368-c43eb7d0da7f" , series: "", repetições: ""}
    ],
    Perna: [
      { id: 46, exercicio: "Afundo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fafundo-exercicio.gif?alt=media&token=6f64b34c-2b7c-49a9-aa30-4f6bb9f1efc5" , series: "", repetições: ""},
      { id: 47, exercicio: "Agachamento Sumô com Halter", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fagachamento%20sumo%20com%20halter.gif?alt=media&token=bc004d10-7a8c-407a-ad84-532fd9597d04", series: "", repetições: ""},
      { id: 48, exercicio: "Agachamento no Smith", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fagachamento%20no%20smith.gif?alt=media&token=09076ca0-4b66-4c1a-9732-660f6033aeb1", series: "", repetições: ""},
      { id: 49, exercicio: "Cadeira Adutora", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fcadeira%20abdutora.gif?alt=media&token=69ad6525-050d-4cb1-89c7-2f585599e4f9" , series: "", repetições: ""},
      { id: 50, exercicio: "Cadeira Extensora", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2FCadeira%20Extensora.gif?alt=media&token=e3daba6e-b2ef-4f3e-9a34-06002705d721", series: "", repetições: ""},
      { id: 51, exercicio: "Cadeira Flexora", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fcadeira%20flexora.gif?alt=media&token=6204dc7c-a63d-400d-9eba-ff767c8204ba", series: "", repetições: ""},
      { id: 52, exercicio: "Elevação Pélvica", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2FElevacao%20Pelvica.gif?alt=media&token=acbd7f98-25e3-4765-8f5d-d2c3dfb3ca84" , series: "", repetições: ""},
      { id: 53, exercicio: "Hack Machine", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2FHack%20Machine.gif?alt=media&token=bd74ca73-e96f-4af2-8259-796ea45bb45c", series: "", repetições: ""},
      { id: 54, exercicio: "Leg Press 45°", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2FLeg%20Press.gif?alt=media&token=a5e99776-be44-41e4-ba1d-ae1f0b6bca2a", series: "", repetições: ""},
      { id: 55, exercicio: "Levantamento Terra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2FLevantamento%20Terra.gif?alt=media&token=c304574c-aa51-489e-9ca1-009a36f38583" , series: "", repetições: ""},
      { id: 56, exercicio: "Panturrilha Sentada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fpanturrilha%20sentada.gif?alt=media&token=8a3c9e6f-e84e-4cdb-ba94-002658cbc48e", series: "", repetições: ""},
      { id: 57, exercicio: "Panturrilha em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fpanturrilha%20em%20p%C3%A9.gif?alt=media&token=1beb7433-9401-44a3-b07b-62f7f440cb40", series: "", repetições: ""},
      { id: 58, exercicio: "Stiff", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fstiff.gif?alt=media&token=6cd0010d-0fa4-4b6f-8f3a-432ad50316fc", series: "", repetições: ""},
      // { id: 59, exercicio: "Aquecimento", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/perna%2Fpolichinelo.gif?alt=media&token=c33bbf95-a6d2-4a42-ada3-400f17e382fc", series: "", repetições: ""}
    ],
    Peito: [
      { id: 60, exercicio: "Mergulho em Paralelas", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2Fbarras%20paralelas.gif?alt=media&token=017dc141-a013-4199-a857-3ac88acd4f8e" , series: "", repetições: ""},
      { id: 61, exercicio: "Crossover na Polia Alta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FCrossober%20na%20Polia%20Alta.gif?alt=media&token=3e07ff30-ca86-448a-96c7-e4af93b3924f", series: "", repetições: ""},
      { id: 63, exercicio: "Crucifixo Cross", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FCrucifixo%20Cross.gif?alt=media&token=f31c90e7-46f7-4c75-9ce2-14c4a957db48" , series: "", repetições: ""},
      { id: 32, exercicio: "Crossover no Cabo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FCrossover%20no%20Cabo.gif?alt=media&token=425c4cd5-810b-45dd-a6cd-a62c80eaed39", series: "", repetições: ""},
      { id: 63, exercicio: "Crucifixo Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FCrucifixo%20Maquina.gif?alt=media&token=ba286d22-de12-4836-b3f8-c34c3fd75b10" , series: "", repetições: ""},
      { id: 64, exercicio: "Flexão de Joelho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FFlex%C3%A3o%20com%20joelho.gif?alt=media&token=c076e29d-77cf-4b1d-9343-8875d58c1996", series: "", repetições: ""},
      { id: 65, exercicio: "Flexão no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FFlex%C3%A3o%20no%20Banco.gif?alt=media&token=5c9b8fe4-204c-4e5e-8dc7-f39faf0c01a9", series: "", repetições: ""},
      { id: 66, exercicio: "Flexão no Banco Inclinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FFlex%C3%A3o%20no%20Banco%20Inclinado.gif?alt=media&token=4fec08e3-8859-48e6-af48-b27a55cc222b" , series: "", repetições: ""},
      { id: 67, exercicio: "Supino Reto", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2FSupino%20com%20Halter.gif?alt=media&token=2a850656-8e89-4cae-bd63-5d64c130042f", series: "", repetições: ""},
      { id: 68, exercicio: "Voador", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2Fvoador.gif?alt=media&token=428a2565-cde7-4974-8c51-1aba3ec468f5", series: "", repetições: ""},
      { id: 69, exercicio: "Supino Declinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/peito%2Fsupino%20declinado.gif?alt=media&token=19f9fcaf-49d5-4ba1-a7bf-b7035f329430" , series: "", repetições: ""}
    ],
    Gluteos: [
      { id: 70, exercicio: "Elevação Pélvica", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2Feleva%C3%A7%C3%A3o%20P%C3%A9lvica.gif?alt=media&token=969f9d91-dfe3-4c90-9867-acec07239f9b" , series: "", repetições: ""},
      { id: 71, exercicio: "Stiff com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FStiff%20com%20Halteres.gif?alt=media&token=ee681fc0-9f67-4ff9-b4bd-ec284d0cef05", series: "", repetições: ""},
      { id: 72, exercicio: "Passada com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPassada%20com%20Halteres.gif?alt=media&token=e2c942df-449d-42b5-b6b1-db4a5962790c", series: "", repetições: ""},
      { id: 73, exercicio: "Levantamento com Barra Deadlift", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FLevantamento%20de%20Barra%20Deadlift.gif?alt=media&token=eb1bd9d0-6350-4a1e-a1c9-e28d0f5b701b" , series: "", repetições: ""},
      { id: 74, exercicio: "Coice no Cabo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FCoice%20no%20Cabo.gif?alt=media&token=8a5a04cb-6693-4ed7-a995-eefa05e8e713", series: "", repetições: ""},
      { id: 75, exercicio: "Agachamento com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAgachamento%20com%20Barragif.gif?alt=media&token=f4a6ab20-9712-4413-b72e-a0e9ca9dd870", series: "", repetições: ""},
      { id: 76, exercicio: "Agachamento Sumô", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAgachamento%20Sum%C3%B4.gif?alt=media&token=d03f4332-d5b0-4892-8278-00746d94375b" , series: "", repetições: ""},
      { id: 77, exercicio: "Abdução de Quadril Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAbdu%C3%A7%C3%A3o%20de%20Quadril%20M%C3%A1quina.gif?alt=media&token=ca580de6-0fa6-4d9c-b334-b62238f6e5d3", series: "", repetições: ""},
      { id: 70, exercicio: "Adução de Quadris Ponte", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAdu%C3%A7%C3%A3o%20de%20Quadris%20Ponte.gif?alt=media&token=35a55997-83db-4c74-b4b5-1e71e4f464c8" , series: "", repetições: ""},
      { id: 71, exercicio: "Chute Coice", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FChute%20Coice.gif?alt=media&token=cfc9e4d9-a5ce-4028-aef1-f2087251b4f3", series: "", repetições: ""},
      { id: 72, exercicio: "Extensão de Quadris em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FExtens%C3%A3o%20de%20Quadris%20em%20P%C3%A9.gif?alt=media&token=13021121-235d-40b8-82fb-483af27f4308", series: "", repetições: ""},
      { id: 73, exercicio: "Impulso de Quadril com Peso Corporal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FImpulso%20de%20Quadril%20com%20Peso%20Corporal.gif?alt=media&token=b6d208f7-c29e-4c31-b6d5-a2096a49f51a" , series: "", repetições: ""},
      { id: 74, exercicio: "Levantamento Terra com Halter", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FLevantamento%20Terra%20com%20Halter.gif?alt=media&token=faaad005-7d5e-44c8-9b65-2d64d60bf78c", series: "", repetições: ""},
      { id: 75, exercicio: "Ponte de Gluteo com uma Perna no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPonte%20de%20Gluteo%20com%20uma%20Perna%20no%20Banco.gif?alt=media&token=4dc2f401-84e3-4561-abdb-699baaf728e2", series: "", repetições: ""},
      { id: 76, exercicio: "Ponte no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPonte%20no%20Banco.gif?alt=media&token=c51e13d1-118c-44a9-b9d9-6bd888c7d3b5" , series: "", repetições: ""},
    ],
    Antebraco: [
      { id: 78, exercicio: "Suspensão na Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FSuspens%C3%A3o%20na%20Barra.gif?alt=media&token=0822b9f7-4d8c-4167-95a8-5b143a44dbc1", series: "", repetições: "" },
      { id: 79, exercicio: "Rosca de Punho Martelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Punho%20Martelo.gif?alt=media&token=3263ff27-1cef-489a-84eb-6f25620a0ae7", series: "", repetições: "" },
      { id: 80, exercicio: "Rosca de Punho Invertido Sentado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Punho%20Invertida%20Sentado.gif?alt=media&token=fce4f19d-21e8-472c-929c-02db821f87f6", series: "", repetições: "" },
      { id: 81, exercicio: "Rolo de Pulso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRolo%20de%20Pulso.gif?alt=media&token=f49ef4f6-52bd-482e-8204-6e6b9021cfd4", series: "", repetições: "" },
      { id: 82, exercicio: "Extensão de Punho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FExtens%C3%A3o%20de%20Punho.gif?alt=media&token=ca80ee99-7fd0-4248-9ae0-9a5739c5e080", series: "", repetições: "" },
      { id: 191, exercicio: "Alongamento de Pulso em Círculos", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FAlongamento%20de%20Pulso%20em%20C%C3%ADrculos.gif?alt=media&token=ecad5e5a-f4fd-43ce-b873-099b9d8f755a", series: "", repetições: "" },
      { id: 192, exercicio: "Rosca Martelo com Elásticos", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20Martelo%20com%20El%C3%A1sticos.gif?alt=media&token=2c061fed-d2e5-4d97-8a75-43cf9c770fb3", series: "", repetições: "" },
      { id: 193, exercicio: "Rosca de Pulso Reversa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Pulso%20Reversa.gif?alt=media&token=612ed02a-36d4-40d5-98ec-ebe9445936a4", series: "", repetições: "" },
      { id: 194, exercicio: "Rosca de Pulso com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Pulso%20com%20Peso.gif?alt=media&token=12caecaf-7c90-475e-9d80-4487b46a491a", series: "", repetições: "" },
      { id: 195, exercicio: "Rosca de pulso com barra atrás das costas", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20pulso%20com%20barra%20atr%C3%A1s%20das%20costas.gif?alt=media&token=a1413338-ab9a-4d61-93b6-5513f2614799", series: "", repetições: "" },
    ],
    Abdomen: [
      { id: 83, exercicio: "Abdominal Cruzado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Cruzado.gif?alt=media&token=85d8fc41-5044-4294-ab67-69bf68a305ae", series: "", repetições: "" },
      { id: 84, exercicio: "Abdominal Declinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Declinado.gif?alt=media&token=b40ff887-9bef-4fef-9221-2e757086e1ff", series: "", repetições: "" },
      { id: 85, exercicio: "Abdominal Invertido", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Invertido.gif?alt=media&token=4091f4f7-81d5-460f-b8af-4cb964655e48", series: "", repetições: "" },
      { id: 86, exercicio: "Abdominal Lateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Lateral.gif?alt=media&token=69783c28-f425-45b6-8f68-3f3c5a45944a", series: "", repetições: "" },
      { id: 87, exercicio: "Abdominal Paralelas", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Paralelas.gif?alt=media&token=5114a909-141a-4ace-afdc-c02893acfb54", series: "", repetições: "" },
      { id: 88, exercicio: "Abdominal Remador", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Remador.gif?alt=media&token=9d25d4ac-9451-4f80-9d51-ae68fae75f12", series: "", repetições: "" },
      { id: 89, exercicio: "Abdominal Tesoura", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Tesoura.gif?alt=media&token=036ab239-9e92-4930-bda1-3422ef887a13", series: "", repetições: "" },
      { id: 90, exercicio: "Abdominal na Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20na%20M%C3%A1quina.gif?alt=media&token=ae72c697-1ca3-4ca5-b549-f471be4b6199", series: "", repetições: "" },
      { id: 91, exercicio: "Abdominal na Polia", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20na%20Polia.gif?alt=media&token=827760b1-0c45-4975-a4a7-9de6f30ace49", series: "", repetições: "" },
      { id: 92, exercicio: "Abdominal Reto", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbodminal%20Reto.gif?alt=media&token=5d19fcf3-b016-4c32-850a-493433f7411f", series: "", repetições: "" },
      { id: 93, exercicio: "Roda Abdominal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FRoda%20Abdominal.gif?alt=media&token=593871e1-5f8c-407e-9c60-861746a550bb", series: "", repetições: "" }
    ],
    Trapezio: [
      { id: 94, exercicio: "Crucifixo Invertido na Polia", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FCrucifixo%20Invertido%20na%20Polia.gif?alt=media&token=a47ecf9a-7753-4b41-b7d7-16e9e46b5804", series: "", repetições: "" },
      { id: 95, exercicio: "Crucifixo Invertido na Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FCrucifixo%20Invertido%20na%20Maquina.gif?alt=media&token=d72c9977-69fe-419e-9622-0750f12d332a", series: "", repetições: "" },
      { id: 96, exercicio: "Elevação em Y", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEleva%C3%A7%C3%A3o%20em%20Y.gif?alt=media&token=0efa75b9-910c-40b9-972c-efd5c05d4576", series: "", repetições: "" },
      { id: 97, exercicio: "Encolhimento de Ombros com Halteres", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20de%20Ombros%20Halter.gif?alt=media&token=d8e44112-1d10-466e-bedb-9f2e227e5342", series: "", repetições: "" },
      { id: 98, exercicio: "Encolhimento por Trás do Corpo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20por%20Tr%C3%A1s%20do%20Corpo.gif?alt=media&token=8bc7f797-9d8a-4ad8-a678-c64456882848", series: "", repetições: "" },
      { id: 99, exercicio: "Crucifixo Invertido na Polia", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FCrucifixo%20Invertido%20na%20Polia.gif?alt=media&token=a47ecf9a-7753-4b41-b7d7-16e9e46b5804", series: "", repetições: "" },
      { id: 100, exercicio: "Crucifixo Invertido na Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FCrucifixo%20Invertido%20na%20Maquina.gif?alt=media&token=d72c9977-69fe-419e-9622-0750f12d332a", series: "", repetições: "" },
      { id: 101, exercicio: "Face Pull", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FFace%20Pull.gif?alt=media&token=035b643c-d3dc-44a4-933d-36821cad806c", series: "", repetições: "" },
      { id: 102, exercicio: "Encolhimento de Ombros com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEoncolhimento%20de%20Ombro%20Barra.gif?alt=media&token=361d0245-f9b1-4c8c-aec1-2c50a5ce93bc", series: "", repetições: "" },
      { id: 103, exercicio: "Remada Alta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FRemada%20Alta.gif?alt=media&token=0ac334e7-239a-40b4-af9b-7b0e44568b8b", series: "", repetições: "" },
    ],
    Cardio: [
      // { id: 104, exercicio: "Eliptico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Feliptico.gif?alt=media&token=ac323ed1-2900-4a49-8a89-628a914ecbda", series: "1", tempo: "15 minutos" },
      { id: 105, exercicio: "Cotovelo no Joelho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fcotovelo%20pra%20joelho.gif?alt=media&token=0a40d46a-3882-451a-b196-27f7b008e33c", series: "3", tempo: "1 minuto" },
      { id: 106, exercicio: "Balançar Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fcords.gif?alt=media&token=acf9faaa-1874-43c6-9c7a-84e85d8c4c08", series: "3", tempo: "1 minuto" },
      { id: 107, exercicio: "Bicicleta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fbicicleta.gif?alt=media&token=6490befc-47d5-4cde-abe2-64b0e60bcef4", series: "1", tempo: "15 minutos" },
      { id: 108, exercicio: "Agachamento com Salto", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fagachamento%20com%20salto.gif?alt=media&token=8aad41b3-6c97-4e06-bc16-f3a5080290b3", series: "3", tempo: " minuto" },
      { id: 109, exercicio: "Mountain Climb", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2FMountain-climber.gif?alt=media&token=34ac1536-cdb3-489a-bafc-f2f27127f08d", series: "3", tempo: "1 minuto" },
      { id: 110, exercicio: "Subir Escada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fsubir%20escada.gif?alt=media&token=0b45f827-2436-4e5a-8739-16f10edef237", series: "1", tempo: "10 minutos" },
      { id: 111, exercicio: "Pular Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fpular%20corda.gif?alt=media&token=39dbce89-5c47-4459-a42c-021a2939996d", series: "3", tempo: "1 minuto" },
      { id: 112, exercicio: "Polichinelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fpolichinelos.gif?alt=media&token=0a7ce33b-57fa-4af9-bba5-dcb920a5ead0", series: "3", tempo: "1 minuto" },
      { id: 113, exercicio: "Esteira", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Festeiraa.gif?alt=media&token=2dc2e0e8-22a8-49a0-976a-28627dc0da2f", series: "1", tempo: "15 minutos" },
    ],
    Panturrilha: [
      { id: 114, exercicio: "Panturrilha com Halter", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20com%20halter.gif?alt=media&token=5cd3b9ce-54b6-4c8f-a767-cb0d013c5af4", series: "", repetições: "" },
      { id: 115, exercicio: "Panturrilha em Pé na Máquina", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20em%20pe%20na%20maquina.gif?alt=media&token=f6624596-d9c3-4847-9934-640e8a8385c7", series: "", repetições: "" },
      { id: 116, exercicio: "Panturrilha em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20em%20pe.gif?alt=media&token=a4411a3d-3447-4dce-9f2c-c6738be1b8ce", series: "", repetições: "" },
      { id: 117, exercicio: "Panturrilha no Leg Press 90º", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20leg%20press%2090.gif?alt=media&token=d0c9e38b-79e3-49ff-9f70-044c331e7f4d", series: "", repetições: "" },
      { id: 118, exercicio: "Panturrilha no Cross", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20no%20cross.gif?alt=media&token=8ffd3532-463f-4b59-b43e-579d6c9ff871", series: "", repetições: "" },
      { id: 119, exercicio: "Panturrilha no Hack", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20no%20hack.gif?alt=media&token=03cd4f3b-f16f-4b0a-b394-6325899e5b3b", series: "", repetições: "" },
      { id: 120, exercicio: "Panturrilha no Leg Press 45º", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20no%20leg%20press%2045.gif?alt=media&token=a98be0de-43d8-4cfa-8680-2c68db500c01", series: "", repetições: "" },
      { id: 121, exercicio: "Panturrilha Sentada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20sentada.gif?alt=media&token=702c3257-12f1-4eea-8196-218c9df2ad54", series: "", repetições: "" },
      { id: 122, exercicio: "Panturrilha Unilateral no Leg Press 45º", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20unilateral%20no%20leg%20press%2045.gif?alt=media&token=22687ea6-14ac-4e21-84ea-0849a2c8ca0a", series: "", repetições: "" },
      { id: 123, exercicio: "Panturrilha em Pé Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20unilateral.gif?alt=media&token=bdf98062-524c-40bc-8861-172c720f2a7f", series: "", repetições: "" },
    ]}
  
  
   export const ExerciciosEmCasa = {
    BicepsCasa: [
      { id: 124, exercicio: "Rosca Martelo com Garrafa de Água", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRosca%20Martelo%20com%20Garrafa%20da%20Agua.gif?alt=media&token=ac98bab8-f0d3-4271-ba84-fab2088c9639" , series: "", repetições: ""},
      { id: 125, exercicio: "Rosca Martelo com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRosca%20Martelo%20com%20El%C3%A1stico.gif?alt=media&token=3592b8d8-ab7f-41e9-bf19-b033a91761a7", series: "", repetições: ""},
      { id: 126, exercicio: "Flexão de Bíceps Sentado com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20de%20Biceps%20Sentado%20com%20El%C3%A1stico.gif?alt=media&token=a72cdbcd-b5c7-4626-9347-a93ee842465d", series: "", repetições: ""},
      { id: 127, exercicio: "Flexão de Cotovelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFelx%C3%A3o%20de%20Cotovelo.gif?alt=media&token=f24d01c5-98bd-4721-b6c2-d547c1761faf", series: "", repetições: ""},
      { id: 128, exercicio: "Flexão de Biceps com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFelx%C3%A3o%20de%20Biceps%20com%20El%C3%A1stico.gif?alt=media&token=3e1ad26a-5df2-45fe-b350-8ce4e8b3c1df", series: "", repetições: ""},
      { id: 129, exercicio: "Biceps Concentrado Flexão de Perna", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FBiceps%20Concentrado%20Flex%C3%A3o%20de%20Perna.gif?alt=media&token=144ae5ed-3e1c-455a-9d80-8d208d71e52b" , series: "", repetições: ""},
      { id: 130, exercicio: "Rosca Alternada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRosca%20Alternada%20com%20El%C3%A1stico.gif?alt=media&token=a9829fff-ab52-4b07-a8b0-b735fd1d5e73" , series: "", repetições: ""},
      { id: 131, exercicio: "Rosca Concentrada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRosca%20Concentrada%20com%20El%C3%A1stico.gif?alt=media&token=4d58d5f9-42e9-44a8-bbac-f5858ee5db6a" , series: "", repetições: ""},
      { id: 132, exercicio: "Flexão Unilateral com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20Unilateral%20com%20El%C3%A1stico.gif?alt=media&token=c87ebd3f-6a4c-4f79-b420-774c0430d29c" , series: "", repetições: ""},
      { id: 133, exercicio: "Rosca Biceps Alternado com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRosca%20Biceps%20Alternado%20com%20Barra.gif?alt=media&token=9a0f1342-6cfd-41c5-bc23-d652d0b3e748" , series: "", repetições: ""}
    ],
    TricepsCasa: [
      { id: 134, exercicio: "Mergulho de Triceps", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FMergulho%20de%20Triceps.gif?alt=media&token=2b667259-c382-4af5-a493-c5bc404a5dcf" , series: "", repetições: ""},
      { id: 135, exercicio: "Extensão de Tríceps", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FExtens%C3%A3o%20de%20Triceps.gif?alt=media&token=b0567e8c-6eb7-4ab9-bbc0-78e73950300b", series: "", repetições: ""},
      { id: 136, exercicio: "Puxada Unilateral Triceps", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxada%20Unilateral.gif?alt=media&token=c880be32-aa6f-46b5-9413-63fa1eeaf7b7", series: "", repetições: ""},
      { id: 137, exercicio: "Crucifixo Inverso com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FCrucifixo%20Inverso%20com%20El%C3%A1stico.gif?alt=media&token=026d2200-96d0-427b-9d6c-3e71565b5d22" , series: "", repetições: ""},
      { id: 138, exercicio: "Puxada Lateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxada%20Lateral%20Triceps.gif?alt=media&token=a31ffc8a-1fc6-422e-b0b4-e7034c368e5f", series: "", repetições: ""},
      { id: 139, exercicio: "Puxada Concentrada Triceps", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxada%20Concentrada%20Triceps.gif?alt=media&token=9c43272c-970e-4829-acbf-c28887f19960", series: "", repetições: ""},
      { id: 140, exercicio: "Mergulho Triceps no Chão", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FMergulho%20Triceps%20no%20Ch%C3%A3o.gif?alt=media&token=a4233a29-7b12-4d3d-a74c-4584257f54f4" , series: "", repetições: ""},
      { id: 141, exercicio: "Tríceps Francês com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2Ftriceps-frances-com-elastico%20(1).gif?alt=media&token=4b044bce-01fc-4a37-ac35-66e1258d5c1a", series: "", repetições: ""},
      { id: 142, exercicio: "Supino com Barra Unilatreal no Chão", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FSupino%20com%20Barra%20Unilateral%20no%20Ch%C3%A3o.gif?alt=media&token=ed02dc48-6904-44b4-bea0-760fd8ea7f62", series: "", repetições: ""},
      { id: 143, exercicio: "Extensão de Tricpes com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FExtens%C3%A3o%20de%20Tricpes%20com%20El%C3%A1stico.gif?alt=media&token=2b868a5f-205d-4328-9e80-a2762502d644" , series: "", repetições: ""}
    ],
    CostasCasa: [
      { id: 144, exercicio: "Rotação de Coluna Ajoelhado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRota%C3%A7%C3%A3o%20de%20Coluna%20Ajoelhado.gif?alt=media&token=6b8a7d8b-5a36-45ed-9a2b-433f3d50f6c0" , series: "", repetições: ""},
      { id: 145, exercicio: "Remada com Peso Corporal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRemada%20com%20peso%20corporal%20na%20porta.gif?alt=media&token=2d5f6eda-fd11-4d32-b001-1bbd84987e5d", series: "", repetições: ""},
      // { id: 146, exercicio: "Remada Invertida", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRemada%20Invertida(Costas).gif?alt=media&token=b46d2216-7281-405b-a367-61786215159b", series: "", repetições: ""},
      { id: 147, exercicio: "Flexão de Cotovelo Reversa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20de%20Cotovelo%20Reversa(Costas).gif?alt=media&token=6b12561d-82ee-4392-b47b-c64e94ac61d2" , series: "", repetições: ""},
      { id: 148, exercicio: "Remada Curvada com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FRemada%20Curvada%20com%20Peso.gif?alt=media&token=e0ce5854-c146-4d85-bc51-fec9b0db8ae9" , series: "", repetições: ""},
      // { id: 149, exercicio: "Barra Fixa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FBarra%20Fixa.gif?alt=media&token=dad5f126-0562-4534-9411-dc3ebc2fa037" , series: "", repetições: ""},
      { id: 150, exercicio: "Extensão de Ombro com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FExtens%C3%A3o%20de%20Ombro%20com%20El%C3%A1stico.gif?alt=media&token=ab8199b0-564a-4fb5-809e-d13f47a9929e" , series: "", repetições: ""},
      // { id: 151, exercicio: "Barra Fixa com Puxada Fechada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxada%20Fechada.gif?alt=media&token=b3317a18-552d-4d1d-9fd3-95327d5f03f1" , series: "", repetições: ""},
      // { id: 152, exercicio: "Barra Fixa com Pegada Invertida", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxada%20com%20Pegada%20Invertida.gif?alt=media&token=b7c33c49-4aa7-4494-946d-55b28b1b7a53" , series: "", repetições: ""},
      { id: 153, exercicio: "Puxador Costas com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPuxador%20Costas%20com%20El%C3%A1stico.gif?alt=media&token=bd262f72-3bfc-4128-a79b-fc0dcf4b9bbb" , series: "", repetições: ""}
    ],
    OmbroCasa: [
      { id: 154, exercicio: "Tesoura", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FTesoura(Peito%20e%20Ombro).gif?alt=media&token=b62850af-c747-4e74-aba5-92cbe6320749" , series: "", repetições: ""},
      { id: 155, exercicio: "Mergulho com Escápula", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FMergulho%20com%20Escapula.gif?alt=media&token=f3df91cf-285a-4b66-be99-51aaf852c72d", series: "", repetições: ""},
      { id: 156, exercicio: "Elevação Lateral de Braços", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FEleva%C3%A7%C3%A3o%20de%20Bra%C3%A7os.gif?alt=media&token=efc0fc66-4eff-40d5-bc7c-30601d734d85", series: "", repetições: ""},
      { id: 157, exercicio: "Elevação Reversa com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FEleva%C3%A7%C3%A3o%20Reversa%20com%20Barra.gif?alt=media&token=54c3529e-3000-4db6-8040-4154dc6836e2" , series: "", repetições: ""},
      { id: 158, exercicio: "Elevação Frontal com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FEleva%C3%A7%C3%A3o%20Frontal%20com%20Peso.gif?alt=media&token=42e9057f-b654-4e6b-8207-ce2b67be4127", series: "", repetições: ""},
      { id: 159, exercicio: "Círculos de Braço", gif: "", series: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FBra%C3%A7o%20Circular.gif?alt=media&token=abf6fa09-57b5-4237-ac0f-f2a70155236e", repetições: "20"},
      { id: 160, exercicio: "Deslizamento de Parede", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FDeslizamento%20de%20Parede%20(ombro).gif?alt=media&token=9f573a8d-f46e-4bd1-bfc7-da2a63df57a3" , series: "", repetições: ""},
      { id: 161, exercicio: "Círculo de Braço Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FCircula%C3%A7%C3%A3o%20de%20Bra%C3%A7o%20Unilateral.gif?alt=media&token=a3668e08-c217-451f-a9a5-1f3e61f9a634", series: "", repetições: ""},
      { id: 162, exercicio: "Desenvolvimento com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2Fdesenvolvimento-de-ombros-com-elasticos.gif?alt=media&token=148f93f5-ace3-48a3-9e62-48dd27c6bb5d", series: "", repetições: ""},
      { id: 163, exercicio: "Encolhimento de Ombros com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2Fencolhimento-de-ombros-com-elastico-trapezio-exercicio.gif?alt=media&token=ba6963cd-3d9c-4a88-bace-9b8b90506942", series: "", repetições: ""}, 
    ],
    PernaCasa: [
      { id: 164, exercicio: "Chute Traseiro com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FChute%20Traseiro%20com%20El%C3%A1stico.gif?alt=media&token=35984ca6-dbb5-42d4-beb9-86bdb9774928" , series: "", repetições: ""},
      { id: 165, exercicio: "Agachamento Sumô", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAdu%C3%A7%C3%A3o%20com%20Elastico.gif?alt=media&token=3cf22b0c-0359-4b75-b42c-447e41ccb12f", series: "", repetições: ""},
      { id: 166, exercicio: "Adutora com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAdu%C3%A7%C3%A3o%20com%20Elastico.gif?alt=media&token=3cf22b0c-0359-4b75-b42c-447e41ccb12f", series: "", repetições: ""},
      { id: 167, exercicio: "Elevação Pélvica", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FEleva%C3%A7%C3%A3o%20Pelvica.gif?alt=media&token=ee31989c-3da2-4df6-8f48-cfa225346523", series: "", repetições: ""},
      { id: 168, exercicio: "Agachamento", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAgachamento.gif?alt=media&token=30b7654b-8ba5-468a-951e-b8e2aa283b32", series: "", repetições: ""},
      { id: 169, exercicio: "Agachamento Búlgaro no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAgachamento%20Bulgaro.gif?alt=media&token=cb45ec7a-e9f5-465e-974b-7fdcacff62a9", series: "", repetições: ""},
      { id: 170, exercicio: "Agachamento na Parede", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAgachamento%20na%20Parede.png?alt=media&token=d823427d-9c81-4d14-b21a-42f4958fd65d", series: "", repetições: ""},
      { id: 171, exercicio: "Extensão de Perna com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FExtens%C3%A3o%20de%20Perna%20com%20El%C3%A1stico.gif?alt=media&token=8e1f7d9e-b752-4ab5-a610-604f8d400a39", series: "", repetições: ""},
      { id: 172, exercicio: "Afundo com Faixa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAfundo%20com%20Faixa.gif?alt=media&token=3e9616e9-ab27-460e-af01-b54e32a18b01", series: "", repetições: ""},
      { id: 173, exercicio: "Afundo Andando", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FAfundo%20Andando.gif?alt=media&token=157250df-91e7-4404-8614-feb9b495e3bb", series: "", repetições: ""}
    ],
    PeitoCasa: [
      { id: 174, exercicio: "Mergulho em Cadeiras", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FMergulho%20em%20Cadeiras(peito%20e%20triceps).gif?alt=media&token=17f653a5-bdfa-4490-a8c5-0b1c1050d130" , series: "", repetições: ""},
      { id: 175, exercicio: "Tesoura", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FTesoura(Peito%20e%20Ombro).gif?alt=media&token=b62850af-c747-4e74-aba5-92cbe6320749", series: "", repetições: ""},
      { id: 176, exercicio: "Flexão", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o(peito).gif?alt=media&token=82a8f68e-d7fa-4fe1-a76f-6086feb2b78c", series: "", repetições: ""},
      { id: 177, exercicio: "Flexão na Parede", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20na%20Parede(peito%20e%20triceps).gif?alt=media&token=eb6d30ac-eb00-4290-9db2-4ef5791869ac" , series: "", repetições: ""},
      { id: 178, exercicio: "Flexão com Palmas", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20com%20Palmas.gif?alt=media&token=2cd106a5-0ed2-4cdb-aa10-0a5f7ab440df", series: "", repetições: ""},
      { id: 179, exercicio: "Flexão Inclinada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20Inclinada(peito).gif?alt=media&token=f5274bf1-3982-42d3-95f1-84222142d82e", series: "", repetições: ""},
      { id: 180, exercicio: "Supino Reto com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FSupino%20Reto%20com%20El%C3%A1stico.gif?alt=media&token=aecfbcea-d4ce-4c85-87ee-eb7ae42d0f50", series: "", repetições: ""},
      { id: 181, exercicio: "Press com Anilha", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPress%20com%20Anilha.gif?alt=media&token=e16b6699-7394-4597-90f4-f22d786c4d51", series: "", repetições: ""},
      { id: 182, exercicio: "Passada de Bola em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FPassada%20de%20Bola%20em%20P%C3%A9.gif?alt=media&token=4528b398-3dc5-45be-860b-e7a2cd9d7a3c", series: "", repetições: ""},
      { id: 183, exercicio: "Flexão Unilateral com Bola", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/casa%2FFlex%C3%A3o%20Unilateral%20com%20Bola.gif?alt=media&token=39f0c228-4289-4c8e-bcf8-dbe1ccfeb7b5", series: "", repetições: ""}
    ],
    GluteosCasa: [
      { id: 70, exercicio: "Elevação Pélvica", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2Feleva%C3%A7%C3%A3o%20P%C3%A9lvica.gif?alt=media&token=969f9d91-dfe3-4c90-9867-acec07239f9b" , series: "", repetições: ""},
      { id: 71, exercicio: "Stiff com Pesos", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FStiff%20com%20Halteres.gif?alt=media&token=ee681fc0-9f67-4ff9-b4bd-ec284d0cef05", series: "", repetições: ""},
      { id: 72, exercicio: "Passada com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPassada%20com%20Halteres.gif?alt=media&token=e2c942df-449d-42b5-b6b1-db4a5962790c", series: "", repetições: ""},
      { id: 73, exercicio: "Levantamento com Barra Deadlift", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FLevantamento%20de%20Barra%20Deadlift.gif?alt=media&token=eb1bd9d0-6350-4a1e-a1c9-e28d0f5b701b" , series: "", repetições: ""},
      { id: 74, exercicio: "Coice com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FCoice%20no%20Cabo.gif?alt=media&token=8a5a04cb-6693-4ed7-a995-eefa05e8e713", series: "", repetições: ""},
      { id: 75, exercicio: "Agachamento com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAgachamento%20com%20Barragif.gif?alt=media&token=f4a6ab20-9712-4413-b72e-a0e9ca9dd870", series: "", repetições: ""},
      { id: 76, exercicio: "Agachamento Sumô", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAgachamento%20Sum%C3%B4.gif?alt=media&token=d03f4332-d5b0-4892-8278-00746d94375b" , series: "", repetições: ""},
      { id: 70, exercicio: "Adução de Quadris Ponte", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FAdu%C3%A7%C3%A3o%20de%20Quadris%20Ponte.gif?alt=media&token=35a55997-83db-4c74-b4b5-1e71e4f464c8" , series: "", repetições: ""},
      { id: 71, exercicio: "Chute Coice", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FChute%20Coice.gif?alt=media&token=cfc9e4d9-a5ce-4028-aef1-f2087251b4f3", series: "", repetições: ""},
      { id: 72, exercicio: "Extensão de Quadris em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FExtens%C3%A3o%20de%20Quadris%20em%20P%C3%A9.gif?alt=media&token=13021121-235d-40b8-82fb-483af27f4308", series: "", repetições: ""},
      { id: 73, exercicio: "Impulso de Quadril com Peso Corporal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FImpulso%20de%20Quadril%20com%20Peso%20Corporal.gif?alt=media&token=b6d208f7-c29e-4c31-b6d5-a2096a49f51a" , series: "", repetições: ""},
      { id: 74, exercicio: "Levantamento Terra com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FLevantamento%20Terra%20com%20Halter.gif?alt=media&token=faaad005-7d5e-44c8-9b65-2d64d60bf78c", series: "", repetições: ""},
      { id: 75, exercicio: "Ponte de Gluteo com uma Perna no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPonte%20de%20Gluteo%20com%20uma%20Perna%20no%20Banco.gif?alt=media&token=4dc2f401-84e3-4561-abdb-699baaf728e2", series: "", repetições: ""},
      { id: 76, exercicio: "Ponte no Banco", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/gluteos%2FPonte%20no%20Banco.gif?alt=media&token=c51e13d1-118c-44a9-b9d9-6bd888c7d3b5" , series: "", repetições: ""},
    ],
    AntebracoCasa: [
      { id: 191, exercicio: "Suspensão na Porta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FSuspens%C3%A3o%20na%20Barra.gif?alt=media&token=0822b9f7-4d8c-4167-95a8-5b143a44dbc1", series: "", repetições: "" },
      { id: 192, exercicio: "Rosca de Punho com Garrafa da Agua", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Punho%20Martelo.gif?alt=media&token=3263ff27-1cef-489a-84eb-6f25620a0ae7", series: "", repetições: "" },
      { id: 193, exercicio: "Rosca de Punho Invertido Sentado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Punho%20Invertida%20Sentado.gif?alt=media&token=fce4f19d-21e8-472c-929c-02db821f87f6", series: "", repetições: "" },
      { id: 194, exercicio: "Rolo de Pulso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRolo%20de%20Pulso.gif?alt=media&token=f49ef4f6-52bd-482e-8204-6e6b9021cfd4", series: "", repetições: "" },
      { id: 195, exercicio: "Extensão de Punho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FExtens%C3%A3o%20de%20Punho.gif?alt=media&token=ca80ee99-7fd0-4248-9ae0-9a5739c5e080", series: "", repetições: "" },
      { id: 191, exercicio: "Alongamento de Pulso em Círculos", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FAlongamento%20de%20Pulso%20em%20C%C3%ADrculos.gif?alt=media&token=ecad5e5a-f4fd-43ce-b873-099b9d8f755a", series: "", repetições: "" },
      { id: 192, exercicio: "Rosca Martelo com Elásticos", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20Martelo%20com%20El%C3%A1sticos.gif?alt=media&token=2c061fed-d2e5-4d97-8a75-43cf9c770fb3", series: "", repetições: "" },
      { id: 193, exercicio: "Rosca de Pulso Reversa", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Pulso%20Reversa.gif?alt=media&token=612ed02a-36d4-40d5-98ec-ebe9445936a4", series: "", repetições: "" },
      { id: 194, exercicio: "Rosca de Pulso com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20Pulso%20com%20Peso.gif?alt=media&token=12caecaf-7c90-475e-9d80-4487b46a491a", series: "", repetições: "" },
      { id: 195, exercicio: "Rosca de pulso com barra atrás das costas", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/antebra%C3%A7o%2FRosca%20de%20pulso%20com%20barra%20atr%C3%A1s%20das%20costas.gif?alt=media&token=a1413338-ab9a-4d61-93b6-5513f2614799", series: "", repetições: "" },
    ],
    AbdomenCasa: [
      { id: 196, exercicio: "Abdominal Cruzado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Cruzado.gif?alt=media&token=85d8fc41-5044-4294-ab67-69bf68a305ae", series: "", repetições: "" },
      { id: 197, exercicio: "Abdominal Declinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Declinado.gif?alt=media&token=b40ff887-9bef-4fef-9221-2e757086e1ff", series: "", repetições: "" },
      { id: 198, exercicio: "Abdominal Invertido", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Invertido.gif?alt=media&token=4091f4f7-81d5-460f-b8af-4cb964655e48", series: "", repetições: "" },
      { id: 199, exercicio: "Abdominal Lateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Lateral.gif?alt=media&token=69783c28-f425-45b6-8f68-3f3c5a45944a", series: "", repetições: "" },
      { id: 200, exercicio: "Abdominal Remador", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Remador.gif?alt=media&token=9d25d4ac-9451-4f80-9d51-ae68fae75f12", series: "", repetições: "" },
      { id: 201, exercicio: "Abdominal Tesoura", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbdominal%20Tesoura.gif?alt=media&token=036ab239-9e92-4930-bda1-3422ef887a13", series: "", repetições: "" },
      { id: 202, exercicio: "Abdominal Reto", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FAbodminal%20Reto.gif?alt=media&token=5d19fcf3-b016-4c32-850a-493433f7411f", series: "", repetições: "" },
      { id: 203, exercicio: "Roda Abdominal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/abdominal%2FRoda%20Abdominal.gif?alt=media&token=593871e1-5f8c-407e-9c60-861746a550bb", series: "", repetições: "" }
    ],
    TrapezioCasa: [
      { id: 204, exercicio: "Encolhimento de Ombros", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20de%20Ombros%20Halter.gif?alt=media&token=d8e44112-1d10-466e-bedb-9f2e227e5342", series: "", repetições: "" },
      { id: 205, exercicio: "Encolhimento por Trás do Corpo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20por%20Tr%C3%A1s%20do%20Corpo.gif?alt=media&token=8bc7f797-9d8a-4ad8-a678-c64456882848", series: "", repetições: "" },
      { id: 206, exercicio: "Face Pull", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FFace%20Pull.gif?alt=media&token=035b643c-d3dc-44a4-933d-36821cad806c", series: "", repetições: "" },
      { id: 207, exercicio: "Crucifixo Invertido com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FCrucifixo%20Invertido%20na%20Polia.gif?alt=media&token=a47ecf9a-7753-4b41-b7d7-16e9e46b5804", series: "", repetições: "" },
      { id: 208, exercicio: "Remada Alta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FRemada%20Alta.gif?alt=media&token=0ac334e7-239a-40b4-af9b-7b0e44568b8b", series: "", repetições: "" },
      { id: 204, exercicio: "Delt Fly Curvado com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FDelt%20Fly%20Curvado%20com%20El%C3%A1stico.gif?alt=media&token=c71aecb9-aecd-4e4d-ace0-a04cb269afda", series: "", repetições: "" },
      { id: 205, exercicio: "Delt Fly Traseiro com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FDelt%20Fly%20Traseiro%20com%20El%C3%A1stico.gif?alt=media&token=ed222b3f-6094-4791-b74e-6faf9ef4f8a8", series: "", repetições: "" },
      { id: 206, exercicio: "Elevação Reversa com Barra", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEleva%C3%A7%C3%A3o%20Reversa%20com%20Barra.gif?alt=media&token=9630833e-f5fc-4787-9d1d-140023b54de3", series: "", repetições: "" },
      { id: 207, exercicio: "Encolhimento de Ombros Inclinado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20de%20Ombros%20Inclinado.gif?alt=media&token=fd5c16ce-40fa-43c2-a3cc-2c458c195735", series: "", repetições: "" },
      { id: 208, exercicio: "Encolhimento de ombros Gittleson", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FEncolhimento%20de%20ombros%20Gittleson.gif?alt=media&token=1001ee4f-13d9-4b96-aa4d-0fdec3321a64", series: "", repetições: "" },
      { id: 204, exercicio: "Flexão Reversa com Cotovelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FFelx%C3%A3o%20Reversa%20com%20Cotovelo.gif?alt=media&token=3d662fcc-1e58-490a-9b59-f0e23c82d097", series: "", repetições: "" },
      { id: 205, exercicio: "Mergulho de Escápula", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FMergulho%20de%20Esc%C3%A1pula.gif?alt=media&token=e65725f6-9208-4478-a4bf-bbd61d26716e", series: "", repetições: "" },
      { id: 206, exercicio: "Press Militar com Peso Corporal", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/trapezio%2FPress%20Militar%20com%20Peso%20Corporal.gif?alt=media&token=7f9e528b-9c32-43f5-8f37-c58a9987aa32", series: "", repetições: "" },
    ],
    CardioCasa: [
      { id: 209, exercicio: "Cotovelo no Joelho", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fcotovelo%20pra%20joelho.gif?alt=media&token=0a40d46a-3882-451a-b196-27f7b008e33c", series: "", repetições: "" },
      { id: 210, exercicio: "Balançar Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fcords.gif?alt=media&token=acf9faaa-1874-43c6-9c7a-84e85d8c4c08", series: "", repetições: "" },
      { id: 211, exercicio: "Bicicleta", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fbicicleta.gif?alt=media&token=6490befc-47d5-4cde-abe2-64b0e60bcef4", series: "", repetições: "" },
      { id: 212, exercicio: "Agachamento com Salto", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fagachamento%20com%20salto.gif?alt=media&token=8aad41b3-6c97-4e06-bc16-f3a5080290b3", series: "", repetições: "" },
      { id: 213, exercicio: "Mountain Climb", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2FMountain-climber.gif?alt=media&token=34ac1536-cdb3-489a-bafc-f2f27127f08d", series: "", repetições: "" },
      { id: 214, exercicio: "Subir Escada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fsubir%20escada.gif?alt=media&token=0b45f827-2436-4e5a-8739-16f10edef237", series: "", repetições: "" },
      { id: 215, exercicio: "Pular Corda", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fpular%20corda.gif?alt=media&token=39dbce89-5c47-4459-a42c-021a2939996d", series: "", repetições: "" },
      { id: 216, exercicio: "Polichinelo", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/cardio%2Fpolichinelos.gif?alt=media&token=0a7ce33b-57fa-4af9-bba5-dcb920a5ead0", series: "", repetições: "" },
    ],
    PanturrilhaCasa: [
      { id: 217, exercicio: "Panturrilha com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20com%20halter.gif?alt=media&token=5cd3b9ce-54b6-4c8f-a767-cb0d013c5af4", series: "", repetições: "" },
      { id: 218, exercicio: "Panturrilha em Pé", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20em%20pe.gif?alt=media&token=a4411a3d-3447-4dce-9f2c-c6738be1b8ce", series: "", repetições: "" },
      { id: 219, exercicio: "Panturrilha com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20no%20cross.gif?alt=media&token=8ffd3532-463f-4b59-b43e-579d6c9ff871", series: "", repetições: "" },
      { id: 220, exercicio: "Panturrilha Sentada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20sentada.gif?alt=media&token=702c3257-12f1-4eea-8196-218c9df2ad54", series: "", repetições: "" },
      { id: 221, exercicio: "Panturrilha em Pé Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2Fpanturrilha%20unilateral.gif?alt=media&token=bdf98062-524c-40bc-8861-172c720f2a7f", series: "", repetições: "" }, 
      { id: 218, exercicio: "Elevação de Panturrilhas com Agachamento", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FEleva%C3%A7%C3%A3o%20de%20Panturrilhas%20com%20Agachamento.gif?alt=media&token=235059d0-9164-41bc-b9b4-5a07bfb374e3", series: "", repetições: "" },
      { id: 219, exercicio: "Extensão de Panturrilha Sentado", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FExtens%C3%A3o%20de%20Panturrilha%20Sentada.gif?alt=media&token=3fe256a9-0115-4561-b1f3-d8ce3a380acb", series: "", repetições: "" },
      { id: 220, exercicio: "Panturrilha Curvada Unilateral", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FPanturrilha%20Curvada%20Unilateral.gif?alt=media&token=8315b975-2ae2-4206-b392-7ded92decd24", series: "", repetições: "" },
      { id: 221, exercicio: "Panturrilha Curvada", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FPanturrilha%20Curvada.gif?alt=media&token=ace12e97-6199-4c69-88dc-314c2152a809", series: "", repetições: "" },
      { id: 218, exercicio: "Panturrilha Sentada com Peso", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FPanturrilha%20Sentada%20com%20Peso.gif?alt=media&token=bfa495ae-410b-410e-a971-1b8171cec289", series: "", repetições: "" },
      { id: 219, exercicio: "Rotação Externa com Elástico", gif: "https://firebasestorage.googleapis.com/v0/b/admin-template-k.firebasestorage.app/o/panturrilha%2FRota%C3%A7%C3%A3o%20Externa%20com%20El%C3%A1stico.gif?alt=media&token=ea323928-96b0-4f15-be9e-35cf403f3ed7", series: "", repetições: "" },
    
    ]
  }