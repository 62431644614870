import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import GatewayPagamento from "../../EfiPay/GatewayPagamento"

export default function TreinoPersonalizado() {
  const amount = 11.99 // Valor fixo

  return (
    <div className="relative min-h-screen overflow-hidden bg-white text-[#190076]">
      <div className="absolute inset-0 bg-white backdrop-filter backdrop-blur-sm"></div>
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-2 sm:p-4">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-[99%] sm:max-w-6xl p-4 border border-gray-200">
          <div className="mt-4">
            <GatewayPagamento amount={amount} />
          </div>

          <div className="mt-4 text-center">
            <p className="text-sm text-[#190076]">
              Treino gerado após o pagamento. Por favor, volte ao site para acessar seu treino.
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#8D24FF] ml-1" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

