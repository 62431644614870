import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import GatewayPagamento from '../../EfiPay/GatewayPagamento';

export default function TreinoEmafrecimento() {
  const [amount, setAmount] = useState(9.99);

  useEffect(() => {
    setAmount(9.99);
  }, []);

 
  return (
    <div className="relative min-h-screen overflow-hidden bg-white text-black">
      <div className="absolute inset-0 bg-white backdrop-filter backdrop-blur-sm"></div>
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-2 sm:p-4">
      <div className="bg-white rounded-lg overflow-hidden w-full max-w-[99%] sm:max-w-7xl p-4 border border-gray-200">
      <div className="mt-4">
            <GatewayPagamento amount={amount}  />
          </div>

          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              Treino gerado após o pagamento. Por favor, volte ao site para acessar seu treino.
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-1" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
